export const companyOverviewData = {
  id: 1,
  title: "Who we are",
  desc: [
    {
      id: 1,
      desc: "We are duly Licensed construction company based in Metro Manila and operates over a country-wide capacity. We always strive to be distinct from any other comapanies in our way of approach, as you will find us working with you every step of the way. Our core team of experienced engineering practitioners will not hesitate to share their thoughts in regard to achieving your end goals in construction.",
    },
    {
      id: 2,
      desc: "This is manifested in the broad spectrum of our client base in the various industries, from High Rise and Stand Alone Residential Projects, to Industrial Manifacturing Complexes, Commercial Retails Stores, Mission Criticial Data Centers, Up to Business Offices Fit-Outs works.",
    },
    {
      id: 3,
      desc: "Started in 2011, as 8GIG Electrical Services, with scope of services in Power Generator sets and Electrical Works. Incorporated in 2015, as General Contractor."
    }
  ],
};

export const companyServicesData = {
  id: 1,
  title: "Services we offer",
  desc: "something short introduction",
  services: [
    {
      id: 1,
      service_title: "Construction",
      details: [
        {
          id: 1,
          desc: "Plan and Layout",
        },
        {
          id: 2,
          desc: "Civil Works",
        },
        {
          id: 3,
          desc: "Electrical Works",
        },
        {
          id: 4,
          desc: "Mechanical Works",
        },
        {
          id: 5,
          desc: "Voice and Data",
        },
        {
          id: 6,
          desc: "Welding Works",
        },
        {
          id: 7,
          desc: "Carpentry Works",
        },
        {
          id: 8,
          desc: "Excavation, Backfilling, & Concreting",
        },
        {
          id: 9,
          desc: "Painting Works",
        },
        {
          id: 10,
          desc: "Glass and Aluminum",
        },
        {
          id: 11,
          desc: "Supply of Materials and Furnitures",
        },
        {
          id: 12,
          desc: "Rehabilitation / Renovation / Fit-Out",
        },
        {
          id: 13,
          desc: "Thermal Scanning",
        },
      ],
      path: "",
    },
    {
      id: 2,
      service_title: "Generator Set",
      details: [
        {
          id: 1,
          desc: "Preventive Maintenance Services for Generator Set(GENSET) (Change Oil, Oil Filter, Fuel Filter, Air Filter, Fuel/Water Separator)",
        },
        {
          id: 2,
          desc: "Services Inspection for all types of GENSET",
        },
        {
          id: 3,
          desc: "Checking, Cleaning and Overhauling of engine",
        },
        {
          id: 4,
          desc: "Supply and Installation of Data KOM and/or Deep Sea Controller",
        },
        {
          id: 5,
          desc: "Service for Actual Load Testing",
        },
        {
          id: 6,
          desc: "Supply and Installation of Auto Crank Controller including Wiring Harness",
        },
        {
          id: 7,
          desc: "Supply and Installation of GENSET Breaker",
        },
        {
          id: 8,
          desc: "Supply and Installation of AVR (Automatic Voltage Regulator)",
        },
        {
          id: 9,
          desc: "Supply and Installation of Alternator",
        },
        {
          id: 10,
          desc: "Rehabilitation of Wiring Harness",
        },
        {
          id: 11,
          desc: "Radiator Overhauling",
        },
        {
          id: 12,
          desc: "Supply and Installation of Battery and Battery Trickle Charger",
        },
      ],
      path: "",
    },
    {
      id: 3,
      service_title: "Air Conditioning Unit",
      details: [
        {
          id: 1,
          desc: "Preventive Maintenance Services of All Types of Air Conditioning Unit",
        },
        {
          id: 2,
          desc: "Supply of Air Conditioning Unit",
        },
        {
          id: 3,
          desc: "Installation of Air Conditioning Unit",
        },
        {
          id: 4,
          desc: "Repairs of Air Conditioning Unit",
        },
      ],
      path: "",
    },
  ],
};

export const missionData = {
  id: 1,
  title: "Mission",
  desc: [
    {
      id: 1,
      desc: "To hone quality individuals and professionals for value adding services and successful projects at reasonable cost, meeting and exceeding clients expectations.",
    },
    {
      id: 2,
      desc: "To Finish and to be able to Turn over the projects with Zero Accident record.",
    },
    {
      id: 3,
      desc: "To be visible to client s during sales and after sales.",
    },
    {
      id: 4,
      desc: "To adapt best practices in the industry that will keep us in pace with the local and global market.",
    },
  ],
};

export const visionData = {
  id: 1,
  title: "Vision",
  desc: [
    {
      id: 1,
      desc: "To be a reputable and well known company that provides value adding services to its client as General Contractor. Through and by upholding to the quality of Design, Materials, Engineers and Manpower to complete the project.",
    },
    {
      id: 2,
      desc: "To prioritize customer satisfaction that productivity and profitability will follow.",
    },
  ],
};

export const clientsData = {
  id: 1,
  title: "Clients",
  desc: [
    {
      id: 1,
      desc: "AMA University and AMA Group of Companies",
    },
    {
      id: 2,
      desc: "DLR Construction Inc. (PLDT-SMART Projects)",
    },
    {
      id: 3,
      desc: "EEI Corporation",
    },
    {
      id: 4,
      desc: "Great Columns Riser Corp.",
    },
    {
      id: 5,
      desc: "Laguna AAAWater Corporation",
    },
    {
      id: 6,
      desc: "Lawson Philippines, Inc.",
    },
    {
      id: 7,
      desc: "Manila Water Philippine Ventures (MWPV)",
    },
  ],
  path: "/our_clients",
};

export const summaryService = {
  id: 1,
  desc: [
    {
      id: 1,
      title: "General Construction",
      image: "gencost.jpg",
    },
    {
      id: 2,
      title: "Engineering and Design",
      image: "",
    },
    {
      id: 3,
      title: "MEPF",
      image: "",
    },
    {
      id: 4,
      title: "Air-Conditioning Supply and Services",
      image: "",
    },
    {
      id: 5,
      title: "Power Generator Supply and Services",
      image: "",
    },
  ],
};

export const featuredNews = [
  {
    id: 1,
    title:
      "Manila Water building molecular lab to detect SARS-COV2 in wastewater",
    date: "March 29, 2022",
    image: "mw.jpeg",
  },
  {
    id: 2,
    title:
      "Manisla Water building molecular lab to detect SARS-COV2 in wastewater",
    date: "March 29, 2022",
    image: "const2.jpeg",
  },
];
