import amaDagupanRenovation1 from '../../assets/image/projects/AMA/AMA Dagupan Renovation/Picture2.png' 
import amaDagupanRenovation2 from '../../assets/image/projects/AMA/AMA Dagupan Renovation/Picture3.png' 
import amaDagupanRenovation3 from '../../assets/image/projects/AMA/AMA Dagupan Renovation/Picture4.png' 
import amaDagupanRenovation4 from '../../assets/image/projects/AMA/AMA Dagupan Renovation/Picture5.png' 
import amaDagupanRenovation5 from '../../assets/image/projects/AMA/AMA Dagupan Renovation/Picture6.png' 
import amaDagupanRenovation6 from '../../assets/image/projects/AMA/AMA Dagupan Renovation/Picture7.png' 
import amaTaclobanRenovation1 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture9.png' 
import amaTaclobanRenovation2 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture10.png' 
import amaTaclobanRenovation3 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture11.png' 
import amaTaclobanRenovation4 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture12.png' 
import amaTaclobanRenovation5 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture13.png' 
import amaTaclobanRenovation6 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture14.png' 
import amaTaclobanRenovation7 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture15.png' 
import amaTaclobanRenovation8 from '../../assets/image/projects/AMA/AMA Tacloban Renovation/Picture16.png' 
import pldtSMCebuSeaside1 from '../../assets/image/projects/PLDT-SMART/PLDT SM CEBU SEASIDE/Picture38.png'
import pldtSMCebuSeaside2 from '../../assets/image/projects/PLDT-SMART/PLDT SM CEBU SEASIDE/Picture38.png'
import pldtOneDigitalStore1 from '../../assets/image/projects/PLDT-SMART/One Digital Store/Picture40.png'
import pldtOneDigitalStore2 from '../../assets/image/projects/PLDT-SMART/One Digital Store/Picture41.png'
import pldtSmartPalawan from '../../assets/image/projects/PLDT-SMART/Smart Palawan/Picture43.png'
import pldtSmartParanaque1 from '../../assets/image/projects/PLDT-SMART/Smart Paranaque/Picture44.png'
import pldtSmartParanaque2 from '../../assets/image/projects/PLDT-SMART/Smart Paranaque/Picture45.png'
import pldtSmartParanaque3 from '../../assets/image/projects/PLDT-SMART/Smart Paranaque/Picture46.png'
import pldtSmartParanaque4 from '../../assets/image/projects/PLDT-SMART/Smart Paranaque/Picture47.png'
import pldtSmartTechnoLab1 from '../../assets/image/projects/PLDT-SMART/Smart Technolab/Picture48.png'
import pldtSmartTechnoLab2 from '../../assets/image/projects/PLDT-SMART/Smart Technolab/Picture49.png'
import pldtSmartTechnoLab3 from '../../assets/image/projects/PLDT-SMART/Smart Technolab/Picture50.png'
import pldtSmartTechnoLab4 from '../../assets/image/projects/PLDT-SMART/Smart Technolab/Picture51.png'
import pldtSmartTechnoLab5 from '../../assets/image/projects/PLDT-SMART/Smart Technolab/Picture52.png'
import pldtSmartImustTnt1 from '../../assets/image/projects/PLDT-SMART/Smart 638 Imus TNT/Picture54.png'
import pldtSmartImustTnt2 from '../../assets/image/projects/PLDT-SMART/Smart 638 Imus TNT/Picture55.png'
import pldtSmartBO6Tnt1 from '../../assets/image/projects/PLDT-SMART/Smart BO6 TNT Project/Picture56.png'
import pldtSmartBO6Tnt2 from '../../assets/image/projects/PLDT-SMART/Smart BO6 TNT Project/Picture57.png'
import pldtSmartBO6Tnt3 from '../../assets/image/projects/PLDT-SMART/Smart BO6 TNT Project/Picture58.png'
import pldtSmartBO6Tnt4 from '../../assets/image/projects/PLDT-SMART/Smart BO6 TNT Project/Picture59.png'
import manilaWaterPalaw1 from '../../assets/image/projects/Manila Water/Estate-Water Palaw/Picture17.png'
import manilaWaterPalaw2 from '../../assets/image/projects/Manila Water/Estate-Water Palaw/Picture18.png'
import manilaWaterOlandes from '../../assets/image/projects/Manila Water/Olandes STP Project/Picture19.png'
import manilaWaterMakatiPumpingStation from '../../assets/image/projects/Manila Water/Makati Pumping Station/Picture23.png'
import manilaWaterIluginSTP from '../../assets/image/projects/Manila Water/Ilugin STP/Picture29.png'
import mcdoRegalado from '../../assets/image/projects/MCDO REGALADO RE-IMAGING/7527e6e1-a558-4718-bbe4-1839060fc448.jpg'
import mcdoMagellanCross from '../../assets/image/projects/MCDO MAGELLANS CROSS CEBU RE-IMAGING/465787449_1655225991727515_8289854161385838807_n.jpg'
import mcdoMCSMakati from '../../assets/image/projects/MCDO MCS MAKATI RE-IMAGING/467184734_579812341332864_5123965413168941725_n.jpg'
import mcdoTarlacMotorway from '../../assets/image/projects/MCDO TARLAC MOTORWAY RE-IMAGING/366694790_847970766688298_5430153083476422719_n.jpg'
import mcdoEton from '../../assets/image/projects/MCDO ETON CENTRIS RE-IMAGING/356799805_1419845602141209_553770778316134095_n.jpg'
import mcdoAlimall from '../../assets/image/projects/MCDO ALIMALL/mcdoalimall.png'
import mcdoPanaderos from '../../assets/image/projects/MCDO PANADEROS/mcdoPanaderos.png'
import mcdoCebuAyala from '../../assets/image/projects/MCDO CEBU AYALA/mcdoCebu.png'
import mcdoBarangka from '../../assets/image/projects/MCDO BARANGKA/mcdoBarangka.png'
import mcdoSukat from '../../assets/image/projects/MCDO SAN ANTONIO SUCAT RE-IMAGING/IMG_20230201_151742_776.jpg'
import mcdoProject8 from '../../assets/image/projects/MCDO Project8/mcdoProject8.png'
import mcdoMadison from '../../assets/image/projects/MCDO MADISON/mcdoMadison.png'
import mcdoBacarra from '../../assets/image/projects/MCDO BACARRA/mcdoBacarra.png'
import mcdoBogo from '../../assets/image/projects/MCDO CEBU BOGO/mcdobogo.png'
import mcdoFaura from '../../assets/image/projects/MCDO PADRE FAURA/mcdoFaura.png'
import sunlifeDagupan from '../../assets/image/projects/SUNLIFE DAGUPAN/sunlifeDagupan.png'
import sunlifeLU from '../../assets/image/projects/SUNLIFE LAUNION/image.png'
import sunlifeBukidnon from '../../assets/image/projects/SUNLIFE BUKIDNON/image.png'
import sunlifeGensan from '../../assets/image/projects/SUNLIFE GENSAN/image.png'
import cebuanaKalinisan from '../../assets/image/projects/CEBUANA KALINISAN/image.png'
import cebuanaNCVI from '../../assets/image/projects/NCVI 5TH FLOOR AND ROOF DECK INTERIOR FIT-OUT/20230811_070249.jpg'
import cebuanaAngeles from '../../assets/image/projects/CEBUANA ANGELES/image.png'


const clientsFullLists = {
  id: 1,
  title: "Clients",
  desc: [
    {
      id: 0,
      title: "",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 1,
      title: "Afable Medical Center",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 2,
      title: "AMA University and AMA Group of Companies",
      projects: [
        {
          id: 1,
          project_service_title: "Construction",
          project_service_type: "Civil Works",
          project_title: "AMACC Tacloban - Relocation",
          project_desc: "New Relocation Site",
          project_amount: "12,973,200.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1FNcjEQsvcuTmCdrsDcGWLLuVJgXZFVVP",
                amaTaclobanRenovation1
            },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=1cHZzSkWyiwI-_Vpxpqq7zEEVKBcAMFuL",
                amaTaclobanRenovation2
              },
            {
              id: 3,
              image:
                // "https://drive.google.com/uc?export=view&id=1UrLjcNFQ2AaAf-bgGONyZy0STtxsSVsD",
                amaTaclobanRenovation3
            },
            {
              id: 4,
              image:
                // "https://drive.google.com/uc?export=view&id=1kdoWpB63xnPqy1adZ_vSdHugqBKgaJO6",
                amaTaclobanRenovation4
            },
            {
              id: 5,
              image:
                // "https://drive.google.com/uc?export=view&id=10nbtHxLpi2_FeJ-_3oB8YwWI0JfgL0bY",
                amaTaclobanRenovation5
            },
            {
              id: 6,
              image:
                // "https://drive.google.com/uc?export=view&id=1tNvn8iSBtKsDVYqYRg3HNY7WprC_QSGs",
                amaTaclobanRenovation6
            },
            {
              id: 7,
              image:
                // "https://drive.google.com/uc?export=view&id=10nJibACurDn-USWNKvxFuXjNBJ7p6VuM",
                amaTaclobanRenovation7
              },
            {
              id: 8,
              image:
              amaTaclobanRenovation8
                // "https://drive.google.com/uc?export=view&id=1qVbhqpiczAaLb0iZBleHkW83Fjh5w4-6",
            },
          ],
        },
        {
          id: 2,
          project_service_title: "Construction",
          project_service_type: "Civil Works",
          project_title: "AMACC Tacloban - Auxilary Works",
          project_desc: "Auxilary Works",
          project_amount: "1,000,000.00",
          project_image: "",
        },
        {
          id: 3,
          project_service_title: "Construction",
          project_service_type: "Civil Works",
          project_title: "AMACC Caloocan - Auxilary Works",
          project_desc: "Auxilary Works",
          project_amount: "800,000.00",
          project_image: "",
        },
        {
          id: 4,
          project_service_title: "Construction",
          project_service_type: "Electrical Works",
          project_title: "AMACC Tacloban - Electrical Works",
          project_desc: "Lighting Works",
          project_amount: "1,000,000.00",
          project_image: "",
        },
        {
          id: 5,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Installation of Air Conditioning Unit",
          project_title: "AMACC Caloocan - Aircon Installation",
          project_desc: "Caloocan Aircon",
          project_amount: "1,000,000.00",
          project_image: "",
        },
        {
          id: 6,
          project_service_title: "Construction",
          project_service_type: "Electrical Works",
          project_title: "AMACC Caloocan - Energization",
          project_desc: "Campus Energization",
          project_amount: "130,000.00",
          project_image: [
            {
              id: 1,
              image:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Workers_bend_reinforcing_bar%2C_or_rebar%2C_to_strengthen_concrete_for_a_facility_that_will_house_372_students_as_part_of_the_Herat_University_Women%27s_Dormitory_Project_in_Herat_province%2C_Afghanistan%2C_March_11_140311-A-DT641-067.jpg/1200px-thumbnail.jpg",
            },
          ],
        },
        {
          id: 7,
          project_service_title: "Air Conditioning Unit",
          project_service_type:
            "Preventive Maintenance Services of All Types of Air Conditioning Unit",
          project_title: "AMACC PANAY - OED",
          project_desc: "AMACC PANAY OED",
          project_amount: "1,000,000.00",
          project_image: "",
        },
        {
          id: 8,
          project_service_title: "Air Conditioning Unit",
          project_service_type: [
            "Supply of Air Conditioning Unit",
            "Installation of Air Conditioning Unit",
          ],
          project_title: "AMA School of Medicine - ACU",
          project_desc: "Supply and Installation of ACU",
          project_amount: "5,200,000.00",
          project_image: "",
        },
        {
          id: 9,
          project_service_title: "Construction",
          project_service_type: "Plan and Layout",
          project_title: "AMA U Cavite Canteen",
          project_desc: "U Cavite Canteen",
          project_amount: "750,000.00",
          project_image: "",
        },
        {
          id: 10,
          project_service_title: "Air Conditioning Unit",
          project_service_type:
            "Preventive Maintenance Services of All Types of Air Conditioning Unit",
          project_title: "AMACC Makati - ACU PMS",
          project_desc: "Makati ACU PMS",
          project_amount: "120,000.00",
          project_image: "",
        },
        {
          id: 11,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Installation of Air Conditioning Unit",
          project_title: "SLC Dasma - ACU installation",
          project_desc: "Installation of 2 units of ACU",
          project_amount: "131,000.00",
          project_image: "",
        },
        {
          id: 12,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Installation of Air Conditioning Unit",
          project_title: "SASN Quezon City - OSM Installation",
          project_desc: "Installation of OSM Aircon",
          project_amount: "194,200.02",
          project_image: "",
        },
        {
          id: 13,
          project_service_title: "Construction",
          project_service_type: "Voice and Data",
          project_title: "AMACC Caloocan - LAN System",
          project_desc: "LAN System @ COMLAB (Phase 2)",
          project_amount: "180,000.00",
          project_image: "",
        },
        {
          id: 14,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Installation of Air Conditioning Unit",
          project_title: "AMACC Head Office - ACU",
          project_desc: "Head Office ACU Server Room",
          project_amount: "28,980.00",
          project_image: "",
        },
        {
          id: 15,
          project_service_title: "Air Conditioning Unit",
          project_service_type:
            "Preventive Maintenance Services of All Types of Air Conditioning Unit",
          project_title: "AMA - ACU PMS",
          project_desc: "Preventive Maintenance of 36 Units of ACU",
          project_amount: "86,400.00",
          project_image: "",
        },
        {
          id: 16,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Repairs of Air Conditioning Unit",
          project_title: "AMACC Cavite - ACU Dismantling",
          project_desc: "Dismantling 12 Units of 3TR ACU",
          project_amount: "12,000.00",
          project_image: "",
        },
        {
          id: 17,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Installation of Air Conditioning Unit",
          project_title: "ACLC Head Office - ACU Installation",
          project_desc: "Installation of 2 OSM ACU @ AMA Chairman's House",
          project_amount: "115,000.00",
          project_image: "",
        },
        {
          id: 18,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Installation of Air Conditioning Unit",
          project_title: "AMA Head Office - ACU Installation",
          project_desc: "Operation Room Installation of 1 Unit of OSM ACU",
          project_amount: "45,000.00",
          project_image: "",
        },
        {
          id: 19,
          project_service_title: "Construction",
          project_service_type: "Renovation",
          project_title: "AMACC Dagupan",
          project_desc: "AMACC Dagupan",
          project_amount: "17,250,000.00",
          project_image: [
            // {
            //   id: 1,
            //   image:
            //     "https://drive.google.com/uc?export=view&id=1as5mLvlgxDUyUTY7pInOr82XY0V57HDu",
            // },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=1TzFUgbT5xF7axtKedTH5aBBjHXj9ZZrB",
                amaDagupanRenovation1
            },
            {
              id: 3,
              image:
                // "https://drive.google.com/uc?export=view&id=165n8f32p4VI6KWH6SAmPEJYqo35z4flT",
                amaDagupanRenovation2
            },
            {
              id: 4,
              image:
                // "https://drive.google.com/uc?export=view&id=13b6D9mGUzCF7zMz61gxRZKtlbUdMhEHp",
                amaDagupanRenovation3

            },
            {
              id: 5,
              image:
                amaDagupanRenovation4
                // "https://drive.google.com/uc?export=view&id=1QlepeFa-HXf7DvkYY1gNIPkIlnNfMqyc",
            },
            {
              id: 6,
              image:
                // "https://drive.google.com/uc?export=view&id=1bD3KBNB0OpLSM7x2asGZTb6dn8ZGBDCK",
                amaDagupanRenovation5
            },
            {
              id: 7,
              image:
                // "https://drive.google.com/uc?export=view&id=1HtZIIVEQgwwSnoEc3B_2nqeiAJqAjruZ",
                amaDagupanRenovation6
            },
          ],
        },
        {
          id: 20,
          project_service_title: "Air Conditioning Unit",
          project_service_type: "Installation of Air Conditioning Unit",
          project_title: "AMA Head Office - ACU Installation",
          project_desc: "Installation of OSM ACU @ Camera View and Server Room",
          project_amount: "104,300.00",
          project_image: "",
        },
      ],
    },
    {
      id: 3,
      title: "Ardent Networks Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 4,
      title: "Aqua Centro MWPV Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 5,
      title: "Club Filipino",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 6,
      title: "Cornersteel Systems Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 7,
      title: "DLR Construction Inc. (PLDT-SMART Projects)",
      projects: [
        {
          id: 1,
          project_service_title: "Construction",
          project_service_type: "Electrical Works",
          project_title: "SSC Electrical Works and Auxiliary Works",
          project_desc:
            "PLDT SM Cebu Seaside SSC Electrical Works and Auxiliary Works",
          project_amount: "1,800,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1bxrcHZLtsI2lG3JoKs6wIrEt9HpvVFYq",
                pldtSMCebuSeaside1
            },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=1bxrcHZLtsI2lG3JoKs6wIrEt9HpvVFYq",
                pldtSMCebuSeaside2
            },
          ],
        },
        {
          id: 2,
          project_service_title: "Construction",
          project_service_type: "Electrical Works",
          project_title: "Smart - PLDT One Digital Store",
          project_desc: "",
          project_amount: "2,350,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1Ql8UoEfBSfYyn74404Dy0sblG-_u38oy",
                pldtOneDigitalStore1
            },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=161sH5tYlu7RncLQStu7vlPBOCTcUIt99",
                pldtOneDigitalStore2

            },
          ],
        },
        {
          id: 3,
          project_service_title: "Construction",
          project_service_type: "Electrical Works",
          project_title: "Smart Palawan",
          project_desc: "",
          project_amount: "1,690,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1rjyfqJlhB65nBDCkFwVk7nH92E_itSR0",
                pldtSmartPalawan
            },
          ],
        },
        {
          id: 4,
          project_service_title: "Generator Set",
          project_service_type: "Rehabilitation of Wiring Harness",
          project_title: "Smart Parañaque",
          project_desc: "",
          project_amount: "18,500,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=19B4zlpGiCL1Aji7gY3LF5lhaYLO1eKw_",
                pldtSmartParanaque1
            },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=1wqFQ_uAmD0W6s7Ff2kknb09XLaXO8s16",
                pldtSmartParanaque2
            },
            {
              id: 3,
              image:
                // "https://drive.google.com/uc?export=view&id=1zRDWFCrNj5vLQSoapJlPJ_qu7PmzpEwI",
                pldtSmartParanaque3
            },
            {
              id: 4,
              image:
                // "https://drive.google.com/uc?export=view&id=1oqDsBGoNuqsrMeqSBVrhqNDa5RBym7IP",
                pldtSmartParanaque4
            },
          ],
        },
        {
          id: 5,
          project_service_title: "Generator Set",
          project_service_type:
            "Supply and Installation of Auto Crank Controller including Wiring Harness",
          project_title: "Smart Technolab",
          project_desc: "",
          project_amount: "18,500,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1RtunAdiIaTFuYdWT5ezKo0byAWgJOC7S",
                pldtSmartTechnoLab1
            },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=1J0kX-qjNTtvS7xVTeD577JpIcXuWBGe5",
                pldtSmartTechnoLab2
            },
            {
              id: 3,
              image:
                // "https://drive.google.com/uc?export=view&id=13117Hh2m-yIFrCSNBkidZx4GpR6_gfEb",
                pldtSmartTechnoLab3
            },
            {
              id: 4,
              image:
                // "https://drive.google.com/uc?export=view&id=1DSKK8pN3UVRy_E_9yBvPK76nlOl3nHS2",
                pldtSmartTechnoLab4
            },
            {
              id: 5,
              image:
                // "https://drive.google.com/uc?export=view&id=1w6jp3vAuZhhIbpqWEmIcq_Q8yOIGNaxn",
                pldtSmartTechnoLab5
            },
          ],
        },
        {
          id: 6,
          project_service_title: "Generator Set",
          project_service_type:
            "Preventive Maintenance Services for Generator Set(GENSET)",
          project_title: "Smart 638 Imus TNT Project",
          project_desc: "",
          project_amount: "7,000,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1OLHlsm9bBye5WYrc7Nqwrv15snaU7HN6",
                pldtSmartImustTnt1
            },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=1Vkc_jZcIhtHexWgHbEm3DoK7Oadci5Lc",
                pldtSmartImustTnt2
            },
          ],
        },
        {
          id: 7,
          project_service_title: "Generator Set",
          project_service_type: "Supply and Installation of GENSET Breaker",
          project_title: "Smart BO6 San Jose TNT Project",
          project_desc: "",
          project_amount: "7,000,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1SB9FO0Vj0Iged9GOr-ULGHlHgyZ9Fz1b",
                pldtSmartBO6Tnt1
            },
            {
              id: 2,
              image:
                // "https://drive.google.com/uc?export=view&id=1skBhtFxxAmgAlPwca2HrnNmDkxg7sGcs",
                pldtSmartBO6Tnt2
            },
            {
              id: 3,
              image:
                // "https://drive.google.com/uc?export=view&id=1TW9kbdTIwnB5ZTYN0bYvVMuO8bSLGVeo",
                pldtSmartBO6Tnt3
            },
            {
              id: 4,
              image:
                // "https://drive.google.com/uc?export=view&id=1Mey30kuc2AAbPrIbtpM0KfzuVW-3rspv",
                pldtSmartBO6Tnt4
            },
          ],
        },
      ],
    },
    {
      id: 8,
      title: "EEI Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 9,
      title: "EEI Business Solutions, Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 10,
      title: "Esco, Inc. (Energy Specialist Company, Inc.)",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 11,
      title: "Far North Luzon General Hospital and Training Center",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 12,
      title: "Fastrak Services Inc.",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 13,
      title: "FGP Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 14,
      title: "First Gen Corporation",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 15,
      title: "First PGMC Enterprises Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 16,
      title: "Fortis PH Engineering Services",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 17,
      title: "Gold and Access Food Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 18,
      title: "Great Columns Riser Corp.",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 19,
      title: "Infinite Options Inc",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 20,
      title: "JAE Philippines, Inc.",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 21,
      title: "Jehan Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 22,
      title: "Knights of Columbas Fraternal Association of the Phil., Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 23,
      title: "Laguna AAAWater Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 24,
      title: "Lawson Philippines, Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 25,
      title: "Lixil Philippines LTD. Co.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 26,
      title: "Manila Water Company, Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 27,
      title: "Manila Water Philippine Ventures (MWPV)",
      projects: [
        {
          id: 1,
          project_service_title: "Construction",
          project_service_type: "MEPF",
          project_title: "Estate - Water Palaw Office Renovation",
          project_desc: "",
          project_amount: "1,400,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1tX4OT6kzXE6jnulKK8MQzyXyJA4R0w8B",
                manilaWaterPalaw1
            },
          ],
        },
        {
          id: 2,
          project_service_title: "Construction",
          project_service_type: "MEFP",
          project_title: "Olandes STP Project",
          project_desc: "",
          project_amount: "15,000,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1jOK5EF_LcSE7Q64KMUmMoImxMOE6pyDP",
                manilaWaterOlandes
            },
            {
              id: 2,
              image:
                "https://drive.google.com/uc?export=view&id=1a09D4kV15HXdSK5ubhqzsPfYtJW4BeQr",
            },
            {
              id: 3,
              image:
                "https://drive.google.com/uc?export=view&id=1x4XlhUOsqgOQAIDnDckuXZDu2yXvaGm8",
            },
            {
              id: 4,
              image:
                "https://drive.google.com/uc?export=view&id=1oCsq8A5o5oxOgk0GY2IX-CELlbLnWKWO",
            },
          ],
        },
        {
          id: 3,
          project_service_title: "Construction",
          project_service_type: "MEPF",
          project_title: "Makati Pumping Station - Facility Upgrade Project",
          project_desc: "",
          project_amount: "9,900,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1j4VgF86ym5SvDMb6iKuHE44V17tBQiAM",
                manilaWaterMakatiPumpingStation
            },
            {
              id: 2,
              image:
                "https://drive.google.com/uc?export=view&id=1oOW_i60BePc4qRYf9S-IW9uDtmvH1Xue",
            },
            {
              id: 3,
              image:
                "https://drive.google.com/uc?export=view&id=1WyOSzmKEibuZIlTzwtPctnPWfm0-qlaC",
            },
            {
              id: 4,
              image:
                "https://drive.google.com/uc?export=view&id=1uXhewOQdPeG0LcMsOV6taT17NzxsBYyf",
            },
            {
              id: 5,
              image:
                "https://drive.google.com/uc?export=view&id=1Bce2hk73rYC_QpCpVg4jzjwf9zkmXPez",
            },
            {
              id: 6,
              image:
                "https://drive.google.com/uc?export=view&id=19WzQ3iH5RWRyTWatkOOqcLVjj2Qf7qib",
            },
          ],
        },
        {
          id: 4,
          project_service_title: "Construction",
          project_service_type: "MEPF",
          project_title:
            "Ilugin STP-Waste Water Laboratory for Surveillance of SARS-COV-2",
          project_desc: "",
          project_amount: "16,500,000.00",
          project_image: [
            {
              id: 1,
              image:
                // "https://drive.google.com/uc?export=view&id=1Zl78ptMOF81hNvw4tXenePsAYs9FmtPU",
                manilaWaterIluginSTP
            },
            {
              id: 2,
              image:
                "https://drive.google.com/uc?export=view&id=1Y-2cUgUJ3pX_oLtJpH_SZZv57Q8fRVlq",
            },
            {
              id: 3,
              image:
                "https://drive.google.com/uc?export=view&id=1zTK6cWzVPv8K7hAucmM2jW4efx83aLIL",
            },
            {
              id: 4,
              image:
                "https://drive.google.com/uc?export=view&id=1RdI-nGw2O6tBfRacUP5l_Xx0cm323LKZ",
            },
            {
              id: 5,
              image:
                "https://drive.google.com/uc?export=view&id=1N3gaCE-y2D6wHxw2FADgsPyPjz6E_EUg",
            },
            {
              id: 6,
              image:
                "https://drive.google.com/uc?export=view&id=1A-PTnNv8PKg0h2GY9cQNaipbkl-K89RE",
            },
          ],
        },
      ],
    },
    {
      id: 28,
      title: "MI Manila Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 29,
      title: "MFC Bistro Foods Corporation",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 30,
      title: "McDonalds (Golden Archers Development Corp.)",
      projects: [
        {
          id: 1,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S REGALADO",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoRegalado
            }
          ],
        },
        {
          id: 3,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S MAGELLAN’S CROSS",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoMagellanCross
            }
          ],
        },
        {
          id: 4,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S MCS MAKATI",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoMCSMakati
            }
          ],
        },
        {
          id: 5,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S TARLAC MOTORWAY",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoTarlacMotorway
            }
          ],
        },
        {
          id: 6,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S ETON",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoEton
            }
          ],
        },
        {
          id: 7,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S ALIMALL",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoAlimall
            }
          ],
        },
        {
          id: 8,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S NEW PANADEROS",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoPanaderos
            }
          ],
        },
        {
          id: 9,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S CEBU AYALA",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoCebuAyala
            }
          ],
        },
        {
          id: 10,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S BARANGKA",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoBarangka
            }
          ],
        },
        {
          id: 11,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S SUCAT",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoSukat
            }
          ],
        },
        {
          id: 12,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S PROJECT 8",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoProject8
            }
          ],
        },
        {
          id: 13,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S MADISON",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoMadison
            }
          ],
        },
        {
          id: 14,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S BACARRA ROAD",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoBacarra
            }
          ],
        },
        {
          id: 15,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S BOGO CEBU",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoBogo
            }
          ],
        },
        {
          id: 16,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "MCDONALD’S PADRE FAURA",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: mcdoFaura
            }
          ],
        },
      ],
    },
    {
      id: 31,
      title: "Medicard Philippines, Inc.",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 32,
      title: "Motor Image Pilipinas (Subaru)",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 33,
      title: "Nutriasia, Inc.",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 34,
      title: "Philippine Band of Mercy",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 35,
      title: "Philippines Bobbin Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 36,
      title: "Philippine Lantel Digital Technology Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 37,
      title: "PI Energy Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 38,
      title: "P.J. Lhuillier Inc. - Cebuana",
      projects: [
        {
          id: 1,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "Cebuana Kalinisan",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: cebuanaKalinisan
            }
          ],
        },
        {
          id: 2,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "Cebuana NCVI",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: cebuanaNCVI
            }
          ],
        }, {
          id: 2,
          project_service_title: "Construction",
          project_service_type: "Construction",
          project_title: "Cebuana Angeles",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: cebuanaAngeles
            }
          ],
        },
      ],
    },
    {
      id: 39,
      title: "Quezon Poultry & Livestock Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 40,
      title: "San Lorenzo Ruiz Home for the Elderly",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 41,
      title: "Sky Cable Corporation",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 42,
      title: "SM Prime Holdings Inc.",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },
    {
      id: 43,
      title: "SPX Philippines Inc. - Shopee",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 44,
      title: "Sun Life of Canada (Philippines) Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "Construction",
          project_service_type: "Carpentry Works",
          project_title: "Sunlife Dagupan",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: sunlifeDagupan
            }
          ],
        },{
          id: 2,
          project_service_title: "Construction",
          project_service_type: "Carpentry Works",
          project_title: "Sunlife Bukidnon",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: sunlifeBukidnon
            }
          ],
        },{
          id: 3,
          project_service_title: "Construction",
          project_service_type: "Carpentry Works",
          project_title: "Sunlife La Union",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: sunlifeLU
            }
          ],
        },{
          id: 4,
          project_service_title: "Construction",
          project_service_type: "Carpentry Works",
          project_title: "Sunlife Gensan",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: sunlifeGensan
            }
          ],
        },
      ],
    },
    {
      id: 45,
      title: "Sun Life Grepa Financial, Inc.",
      projects: [
        {
          id: 1,
          project_service_title: "",
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: [],
        },
      ],
    },
    {
      id: 46,
      title: "Thunderbird Resort - La Union",
      projects: [
        {
          id: 1,
          project_service_type: "",
          project_title: "",
          project_desc: "",
          project_amount: "",
          project_image: "",
        },
      ],
    },  {
      id: 46,
      title: "Imperial Tobacco",
      projects: [
        {
          id: 1,
          project_service_type: "Civil Works",
          project_service_title: "Construction",
          project_title: "PBC PRODUCTION - LEAF AREA RENOVATION",
          project_desc: "",
          project_amount: "",
          project_image: [
            {
              id: 1,
              image: ""
            }
          ],
        },
      ],
    },
  ],
};

export { clientsFullLists };
