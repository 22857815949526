import React, { useEffect, useState } from "react";
import NavBar from "../navigationBar/NavBar";
import ImageSlider from "../slider/imageSlider";
import { HomeSliderImages } from "../data/sliderData";
import { companyOverviewData } from "../data/companyOverviewData";
import manila_water_8gigki_project_photo from "../../assets/image/mw.jpeg";
import HomeServices from "../homeServices/homeServices";
import ModalArticle from "../article/modalArticle";
import Footer from "../footer/footer";
import ReactGA from "react-ga4";
import "./home.scss";
import HomeGetQuote from "../homeGetQuote/homeGetQuote";

export default function Home() {
    const [isReady, setIsReady] = useState();
    const publicPath = window.location.origin;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    useEffect(() => {
        window.scrollTo(0, 0);
        setIsReady(true);
    }, []);

    return (
        <div className="home__cont__wrapper">
            {isReady && (
                <>
                    <NavBar />
                    <ImageSlider slides={HomeSliderImages} />
                    <div className="home__article__featured">
                        <div className="home__article__featured__header__cont">
                            <h3 className="mission__article__header">Featured News</h3>
                        </div>
                        <div className="home__article__content__cont">
                            <div className="home__article__header__cont">
                                <h2 className="home__article__header">
                                    Manila Water building molecular lab to detect SARS-COV2 in wastewater
                                </h2>
                                <span>published on March 29, 2022 by Manila Bulletin</span>
                            </div>
                            <div className="home__article__content">
                                <div className="home__article__image__cont">
                                    <img
                                        src={manila_water_8gigki_project_photo}
                                        alt="manila water 8gigki project"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="home__article__cta__cont">
                                    <div className="home__article__cta__content">
                                        <p>
                                            Manila Water affirms that the new facility will support the
                                            current efforts of the different local government units to address
                                            this pandemic. Operating the molecular laboratory also allows the
                                            company to provide services beyond its regular wastewater
                                            treatment and operations.
                                        </p>
                                    </div>
                                    <div className="home__article__cta__content">
                                        <a
                                            href="https://mb.com.ph/2022/03/29/manila-water-building-molecular-lab-to-detect-sars-cov2-in-wastewater/"
                                            target="_blank"
                                            title="Manila Water Project with 8gig Konstruct Inc."
                                        >
                                            <button className="home__article__cta">Click to read more</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home__article__cont">
                        <div className="home__article__overview">
                            {/* <ModalArticle data={companyOverviewData} /> */}
                            <div className={`mission__article__cont--home`}>
                                <div className="mission__article__content__cont--home">
                                    <div className={`mission__article__header__cont--home`}>
                                        <h3 className="mission__article__header--home">
                                            {companyOverviewData.title}
                                        </h3>
                                    </div>
                                    <div className={`mission__article__content--home`}>
                                        {companyOverviewData.desc.map((item) => {
                                            return <p key={item.id}>{item.desc}</p>;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <HomeServices />
                    <div className="home__cta__cont">
                        <div className="home__download__cont">
                            <div className="home__download__cta__cont">
                                <a
                                    // href="https://drive.google.com/uc?id=1rZbE5_HaSEoSKtI3Asck-rdWsZ7oLpoT&export=download" old
                                    href="https://drive.google.com/uc?id=1SqbgZ6_-dBnfD23vZnL3R3OSv4HYGrrW&export=download"
                                    target="_blank"
                                    className="home__download__cta"
                                    title="download 8gigki brochure"
                                >
                                    <img
                                        src={`${publicPath}/download.png`}
                                        alt="8gigki download brochure icon"
                                        className="home__download__icon"
                                        loading="lazy"
                                    />
                                </a>
                            </div>
                            <div className="home__download__header_cont">
                                <h3 className="home__download__header">get our company profile</h3>
                            </div>
                        </div>
                        <HomeGetQuote />
                    </div>
                    <Footer />
                </>
            )}
        </div>
    );
}
